import React, { useState, useCallback, Suspense } from 'react';
import { useMessageHandler } from '../hooks/useMessageHandler';  // Importation du hook personnalisé
import '../css/bouton.css';
import '../css/formulaire.css';
import '../css/tableau.css';

// Utilisation de React.lazy pour charger les composants de manière paresseuse
const UserList = React.lazy(() => import('./UserList'));
const AddUser = React.lazy(() => import('./AddUser')); 

const AdminPanel = () => {
  const [selectedTab, setSelectedTab] = useState('addUser');

  const { error, successMessage, setError, setSuccessMessage, clearMessagesAfterTimeout } = useMessageHandler();

  const handleTabChange = useCallback((tab) => {
    setSelectedTab(tab);
    setError('');
    setSuccessMessage('');
  }, [setError, setSuccessMessage]);

  return (
    <div className="flex flex-row min-h-screen">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8">
              <div className="pb-4">
                <h1 className="text-2xl font-bold">Gestion Administrateur</h1>
              </div>
              <div className="border-b border-gray-300 my-4">
                <nav className="flex space-x-4">
                  <button
                    className={`py-2 px-4 ${selectedTab === 'addUser' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => handleTabChange('addUser')}
                  >
                    Ajouter un Utilisateur
                  </button>
                  <button
                    className={`py-2 px-4 ${selectedTab === 'userList' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => handleTabChange('userList')}
                  >
                    Liste des Utilisateurs
                  </button>
                </nav>
              </div>
              <div className="flex-grow p-6 bg-white">
                <div className="max-w-6xl mx-auto bg-white p-6 border rounded-lg">
                  <Suspense fallback={<div>Chargement...</div>}>
                    {selectedTab === 'addUser' && (
                      <AddUser
                        error={error}
                        successMessage={successMessage}
                        setError={setError}
                        setSuccessMessage={setSuccessMessage}
                        clearMessagesAfterTimeout={clearMessagesAfterTimeout}
                      />
                    )}
                    {selectedTab === 'userList' && <UserList />}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
