import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../ProjectContext';
import '../css/bouton.css';
import '../css/formulaire.css';
import '../css/tableau.css';

const CreateProject = ({ onClose }) => {
  const [formData, setFormData] = useState({
    NomDuProjet: '',
    Titre: '',
    Commentaire: ''
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { addProject } = useProjects();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.NomDuProjet.trim() || !formData.Titre.trim()) {
      setMessage('Les champs Nom du Projet et Titre sont requis.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const response = await axios.post(`${apiBaseUrl}/projets`, formData, config);
      const newProject = response.data;
  
      setMessage('Projet créé avec succès.');
      setFormData({ NomDuProjet: '', Titre: '', Commentaire: '' });
      addProject(newProject);
      navigate('/view-projects');
      onClose();
    } catch (error) {
      if (error.response) {
        setMessage('Erreur lors de la création du projet. Veuillez réessayer.');
      } else {
        setMessage('Erreur lors de la création du projet. Veuillez réessayer.');
      }
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-3xl">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Créer un nouveau projet</h2>
      <p className="text-sm text-gray-500 text-right"><span className="text-red-500">*</span> : Champs  obligatoires</p>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Nom du Projet <span className="required" title="Ce champ est obligatoire">*</span></label>
          <input 
            type="text"
            name="NomDuProjet"
            value={formData.NomDuProjet}
            onChange={handleChange}
            placeholder="Entrez le nom du projet"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Titre <span className="required" title="Ce champ est obligatoire">*</span></label>
          <input 
            type="text"
            name="Titre"
            value={formData.Titre}
            onChange={handleChange}
            placeholder="Entrez un titre pour le projet"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Commentaire</label>
          <textarea 
            name="Commentaire"
            value={formData.Commentaire}
            onChange={handleChange}
            placeholder="Ajoutez un commentaire (facultatif)"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          ></textarea>
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter un projet</button>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
