import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';

const EditDashboard = ({ dashboard, onEditSuccess, onClose }) => {
  const { id, detailId } = useParams(); // ID du projet et ID du détail
  const [dashboardData, setDashboardData] = useState({ ...dashboard });
  const [message, setMessage] = useState('');

  useEffect(() => {
    setDashboardData({ ...dashboard });
  }, [dashboard]);

  // Mapping des types de dashboard
  const dashboardTypeLabels = {
    SyntheticMonitoring: 'Synthetic Monitoring',
    Infrastructure: 'Infrastructure',
    InfraEndpoint: 'Infrastructure Endpoint',
    EndpointAppPerf: 'Endpoint Application Monitoring',
    DigitalJourney: 'Digital Journey',
    DigitalExpMon: 'Digital Experience Monitoring',
    LoadTest: 'Load Test',
    MeteoServices: 'Météo des Services',
    Autre: 'Autre'
  };

  const handleChange = (e) => {
    setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards/${dashboard.id}`, dashboardData, config);
      setMessage('Dashboard modifié avec succès.');
      if (onEditSuccess) {
        onEditSuccess();
      }
      onClose();
    } catch (error) {
      console.error('Erreur lors de la modification du dashboard:', error);
      setMessage('Erreur lors de la modification. Veuillez réessayer.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 p-2 bg-white rounded max-w-2xl">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Modifier le Dashboard</h2>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <p className="text-sm text-gray-500 text-right"><span className="text-red-500">*</span> : Champs  obligatoires</p>
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom <span className="text-red-500">*</span></label>
          <input 
            type="text" 
            name="nom" 
            value={dashboardData.nom} 
            onChange={handleChange} 
            placeholder="Entrez le nom du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">URL du Dashboard <span className="text-red-500">*</span></label>
          <input 
            type="url" 
            name="url_Dashboard" 
            value={dashboardData.url_Dashboard} 
            onChange={handleChange} 
            placeholder="Entrez l'URL du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Type de Dashboard <span className="text-red-500">*</span></label>
          <select 
            name="TypeDashboard" 
            value={dashboardData.TypeDashboard} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
          >
            {Object.keys(dashboardTypeLabels).map((key) => (
              <option key={key} value={key}>{dashboardTypeLabels[key]}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Modifier</button>
        </div>
      </form>
    </div>
  );
};

export default EditDashboard;
