import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css'; 
import 'flag-icon-css/css/flag-icons.min.css';

import { useAuth } from '../AuthContext';
import '../css/bouton.css';
import '../css/tableau.css';

const UserProfileDetails = ({ setError, setSuccessMessage, error, successMessage }) => {
  const { user } = useAuth();
  const [userData, setUserData] = useState({ nom: '', prenom: '', email: '', numero: '' });

  const clearErrorAfterTimeout = useCallback(() => {
    setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
  }, [setError, setSuccessMessage]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        const response = await axios.get(`${apiBaseUrl}/users/me`, config);
        setUserData(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
        setError('Impossible de charger les données du profil.');
        clearErrorAfterTimeout();
      }
    };
    fetchUserData();
  }, [clearErrorAfterTimeout, setError]);

  const handleProfileChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    const updatedData = { 
      nom: userData.nom,
      prenom: userData.prenom,
      email: userData.email,
      numero: userData.numero // Inclure le numéro formaté
    };

    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/profils/update-profile`, updatedData, config);
      setSuccessMessage('Profil mis à jour avec succès.');
      clearErrorAfterTimeout();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      setError('Erreur lors de la mise à jour du profil. Veuillez réessayer.');
      clearErrorAfterTimeout();
    }
  };

  return (
    <>
      <div className="border-b border-gray-300 pb-4 mb-4">
        <h2 className="text-2xl font-semibold mb-4">Profil de {user.nom}</h2>
      </div>
      {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
      {successMessage && <div className="bg-green-500 text-white p-2 rounded">{successMessage}</div>}
      <form onSubmit={updateProfile}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nom :</label>
          <input
            type="text"
            name="nom"
            value={userData.nom}
            onChange={handleProfileChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Prénom :</label>
          <input
            type="text"
            name="prenom"
            value={userData.prenom}
            onChange={handleProfileChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Email :</label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleProfileChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">Numéro :</label>
          <PhoneInput
            country={'fr'} // Pays par défaut
            value={userData.numero}
            onChange={(numero) => setUserData({ ...userData, numero })} // Mettre à jour le numéro dans le state
            inputClass="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight custom-focus"
            containerClass="w-full"  // Appliquer le style Tailwind w-full au conteneur
            inputStyle={{ width: '100%' }} // Appliquer w-full au champ de saisie
          />
        </div>
        <button
          type="submit"
          className="bouton_vert"
        >
        Mettre à jour
        </button>
      </form>
    </>
  );
};

export default UserProfileDetails;
