import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';
import { useSortedData, useSortConfig, getSortIcon } from './SortUtils'; 

const ProjectDetailsTableau = ({
  details,
  projectId,
  searchQuery,
  setSearchQuery,
  fetchProjectDetails,
  toggleAddDetailModal,
  toggleDocumentsModal,
  handleDeleteDetail,
  handleMouseEnter,
  handleMouseLeave,
  hoveredColumn,
  formatDate
}) => {
  const [editDetailId, setEditDetailId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    TitreCampagne: '',
    Description: '',
    DateCampagne: ''
  });

  const { sortConfig, requestSort } = useSortConfig(); // Utilisation du hook pour la configuration du tri
  const navigate = useNavigate();

  // Conserver la fonction de filtrage
  const filterDetails = () => {
    return details.filter((detail) => {
      return (
        (detail.TitreCampagne && detail.TitreCampagne.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (detail.Description && detail.Description.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (detail.DateCampagne && formatDate(detail.DateCampagne).includes(searchQuery))
      );
    });
  };
  
  const filteredSortedDetails = useSortedData(filterDetails(), sortConfig); // Filtrage + tri

  const handleEditClick = (detail) => {
    setEditDetailId(detail.id);
    setEditFormData({
      TitreCampagne: decodeURIComponent(detail.TitreCampagne),
      Description: decodeURIComponent(detail.Description),
      DateCampagne: detail.DateCampagne.split('T')[0]
    });
  };

  const handleCancelClick = () => {
    setEditDetailId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      await axios.put(`${apiBaseUrl}/projets/${projectId}/details/${editDetailId}`, editFormData, config);
      await fetchProjectDetails();
      setEditDetailId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du détail:', error);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 my-3">Campagnes</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          className="search-bar border p-2 rounded mr-4 custom-focus"
          type="text"
          placeholder="Rechercher par Titre de Campagne, Description ou Date"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={toggleAddDetailModal} className="text-white font-bold py-1 px-2 rounded text-2xl bg-green-800 hover:bg-green-700">
          <i className="fas fa-plus"></i>
        </button>
      </div>

      <div className="mt-6 border overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {filteredSortedDetails.length === 0 ? (
          <div className="text-center p-4">
            <p className="text-lg">Aucun détail n'est disponible</p>
            <p className="text-sm text-gray-500">Cliquez sur le bouton "+" ci-dessus pour ajouter une nouvelle campagne.</p>
          </div>
        ) : (
        <div className="table-responsive" style={{ overflowX: 'auto' }}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                  style={{ width: '25%' }}
                  onClick={() => requestSort('DateCampagne')}
                >
                  <span className="flex justify-between">
                    Date de Campagne
                    {getSortIcon('DateCampagne', sortConfig)}
                  </span>
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                  style={{ width: '25%' }}
                  onClick={() => requestSort('TitreCampagne')}
                >
                  <span className="flex justify-between">
                    Titre de la Campagne
                    {getSortIcon('TitreCampagne', sortConfig)}
                  </span>
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider cursor-pointer"
                  style={{ width: '25%' }}
                  onClick={() => requestSort('Description')}
                >
                  <span className="flex justify-between">
                    Description
                    {getSortIcon('Description', sortConfig)}
                  </span>
                </th>
                <th
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-s font-semibold text-black-900 uppercase tracking-wider"
                  style={{ width: '25%' }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredSortedDetails.map((detail, index) => (
                <tr
                  key={detail.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {editDetailId === detail.id ? (
                    <>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm font-bold">
                        <input
                          type="date"
                          name="DateCampagne"
                          value={editFormData.DateCampagne}
                          onChange={handleInputChange}
                          className="bouton_edit custom-focus"
                        />
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                        <input
                          type="text"
                          name="TitreCampagne"
                          value={editFormData.TitreCampagne}
                          onChange={handleInputChange}
                          className="bouton_edit custom-focus"
                        />
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                        <input
                          type="text"
                          name="Description"
                          value={editFormData.Description}
                          onChange={handleInputChange}
                          className="bouton_edit custom-focus"
                        />
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm space-x-2">
                        <button onClick={handleSaveClick} className="bouton">
                          Enregistrer
                        </button>
                        <button onClick={handleCancelClick} className="bouton">
                          Annuler
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm font-bold">
                        {formatDate(detail.DateCampagne)}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                        {decodeURIComponent(detail.TitreCampagne)}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-black-900 text-sm">
                        {decodeURIComponent(detail.Description)}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex space-x-2 overflow-x-auto">
                          <button
                            onClick={() => navigate(`/projets/${projectId}/details/${detail.id}/dashboards`)}
                            className={`py-1 px-2 rounded ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                          >
                            Dashboards
                          </button>
                          <button
                            onClick={() => toggleDocumentsModal(detail.id)}
                            className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                          >
                            Documents
                          </button>
                          <button
                            onClick={() => handleEditClick(detail)}
                            className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                          >
                            Modifier
                          </button>
                          <button
                            onClick={() => handleDeleteDetail(detail.id)}
                            className={`py-1 px-2 rounded ml-4 ${hoveredColumn === index ? 'bouton' : 'bouton_cacher'}`}
                          >
                            Supprimer
                          </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailsTableau;
