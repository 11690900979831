import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Navbar = () => {
  const { user, logout } = useAuth(); 
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  if (!user) return null;

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const openSupportPage = () => {
    window.open('https://support-logiciels.atakama-technologies.com/pages/UI.php', '_blank');
    setIsOpen(false);
  };

  // Fonction pour obtenir les initiales du prénom et du nom
  const getInitials = (prenom, nom) => {
    const firstNameInitial = prenom?.charAt(0).toUpperCase() || '';
    const lastNameInitial = nom?.charAt(0).toUpperCase() || '';
    return firstNameInitial + lastNameInitial;
  };

  // Utilisation des initiales basées sur `prenom` et `nom` de la table Users
  const userInitials = getInitials(user.prenom, user.nom);

  return (
    <nav className="bg-white text-gray-800 h-14 flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-50">
      <div className="flex items-center">
        <div className="flex items-center justify-center h-14">
          <img src="/logoPowerHouseNudge1.png" alt="PowerHouse Nudge Logo" className="h-12" />
        </div>
      </div>
      <div className="flex items-center space-x-2 relative">
        {/* Affichage de l'email de l'utilisateur */}
        <div className="text-gray-800 font-semibold">
          {user.email}
        </div>
        {/* Cercle avec les initiales de l'utilisateur */}
        <div onClick={toggleDropdown} className="cursor-pointer">
        <div className="w-8 h-8 rounded-full bg-green-800 hover:bg-green-700 text-white flex justify-center items-center font-bold text-sm">
          {userInitials}
        </div>
        </div>
        {/* Menu déroulant */}
        {isOpen && (
          <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-md shadow-lg z-50">
            <button
              onClick={() => handleNavigation('/settings')}
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md flex items-center space-x-2"
            >
              <i className="fas fa-cog text-lg"></i> {/* Icône des paramètres */}
              <span>Paramètres</span>
            </button>
            {user.role === 'ADMIN' && (
              <button
                onClick={() => handleNavigation('/admin')}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 flex items-center space-x-2"
              >
                <i className="fas fa-user-shield text-lg"></i> {/* Icône Admin */}
                <span>Admin</span>
              </button>
            )}
            <button
              onClick={openSupportPage}
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md flex items-center space-x-2"
            >
              {/* SVG Atakama pour Support */}
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 43.000000 43.000000"
                preserveAspectRatio="xMidYMid meet"
                className="h-5 w-5 fill-current text-[#166534] hover:text-[#15803D]"
              >
                <g transform="translate(0.000000,43.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                  <path d="M175 426 c-5 -3 -22 -7 -37 -10 -15 -4 -44 -21 -64 -39 -150 -131
                  -57 -378 143 -377 152 1 256 160 194 295 -20 45 -62 90 -102 110 -26 14 -116
                  28 -134 21z m175 -125 c0 -6 -21 -11 -47 -13 l-48 -3 -3 -82 c-2 -46 -7 -83
                  -12 -83 -11 0 -14 173 -3 183 10 11 113 8 113 -2z m-197 -91 c-30 -49 -59 -90
                  -64 -90 -11 0 -3 17 54 113 21 37 45 67 53 67 7 0 -10 -37 -43 -90z"/>
                </g>
              </svg>
              <span>Support</span>
            </button>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-md flex items-center space-x-2"
            >
              <i className="fas fa-sign-out-alt text-lg"></i> {/* Icône de déconnexion */}
              <span>Déconnexion</span>
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
