import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/bouton.css';
import '../css/tableau.css';

const AddDashboard = ({ onAddSuccess }) => {
  const { id, detailId } = useParams(); // ID du projet et ID du détail

  const [dashboardData, setDashboardData] = useState({
    nom: '',
    url_Dashboard: '',
    TypeDashboard: 'Autre', // Valeur par défaut
    ID_DetailProjet: detailId // Ajout de l'ID du détail du projet
  });
  const [message, setMessage] = useState('');

  // Mapping des types de dashboard
  const dashboardTypeLabels = {
    SyntheticMonitoring: 'Synthetic Monitoring',
    Infrastructure: 'Infrastructure',
    InfraEndpoint: 'Infrastructure Endpoint',
    EndpointAppPerf: 'Endpoint Application Monitoring',
    DigitalJourney: 'Digital Journey',
    DigitalExpMon: 'Digital Experience Monitoring',
    LoadTest: 'Load Test',
    MeteoServices: 'Météo des Services',
    Autre: 'Autre'
  };

  const handleChange = (e) => {
    setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      // Inclure l'ID_DetailProjet dans l'objet envoyé
      const payload = {
        ...dashboardData,
        ID_DetailProjet: detailId 
      };
      await axios.post(`${apiBaseUrl}/projets/${id}/details/${detailId}/dashboards`, payload, config);
      setMessage('Dashboard ajouté avec succès.');
      setDashboardData({ nom: '', url_Dashboard: '', TypeDashboard: 'Autre' }); // Réinitialiser les champs
      if (onAddSuccess) {
        onAddSuccess();
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout du dashboard:', error);
      setMessage('Erreur lors de l\'ajout. Veuillez réessayer.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
      Nouvelle entrée de dashboard
      </h2>
      <p className="text-sm text-gray-500 text-right"><span className="text-red-500">*</span> : Champs  obligatoires</p>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom <span className="text-red-500">*</span></label>
          <input 
            type="text" 
            name="nom" 
            value={dashboardData.nom} 
            onChange={handleChange} 
            placeholder="Entrez le nom du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">URL du Dashboard <span className="text-red-500">*</span></label>
          <input 
            type="url" 
            name="url_Dashboard" 
            value={dashboardData.url_Dashboard} 
            onChange={handleChange} 
            placeholder="Entrez l'URL du dashboard"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Type de Dashboard <span className="text-red-500">*</span></label>
          <select 
            name="TypeDashboard" 
            value={dashboardData.TypeDashboard} 
            onChange={handleChange} 
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm custom-focus"
          >
            {Object.keys(dashboardTypeLabels).map((key) => (
              <option key={key} value={key}>{dashboardTypeLabels[key]}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter un dashboard</button>
        </div>
      </form>
    </div>
  );
};

export default AddDashboard;
